import React, { useState } from "react";

import ModalVideo from "react-modal-video";
import about from "../assets/images/CASAROSALESPEJEADAS.png";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import CTABackground from "../assets/images/bg/cta.png";
import { Link } from 'react-router-dom';

export default function About() {
  const [isOpen, ] = useState(true);
  window.addEventListener("scroll", windowScroll);

  ;
  /**
   * Window scroll
   */
  function windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("is-sticky");
    } else {
      navbar.classList.remove("is-sticky");
    }
  }
  return (
    <>
      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="about">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div className="lg:col-span-5">
            <img src={about} alt="Descripción de la imagen" className="w-full h-auto" />
            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto text-center">Imagen Ilustrativa*</p>
            </div>
            {/* end col */}

            <div className="lg:col-span-7">
              <div className="lg:ms-7">
                <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Sobre Nosotros</h6>

                <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">Con años de experiencia en el mercado inmobiliario, Valdepeñas se destaca por crear viviendas y lotes que no solo cumplen con las necesidades familiares sino que las superan, gracias a nuestro enfoque en calidad, funcionalidad y diseño innovador.</p>

                
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End --> */}
      {/* Project End  */}
      <section
                style={{ backgroundImage: `url(${CTABackground})` }}
                className="py-24 w-full table relative bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-80"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl text-white font-medium">Tu Nuevo Hogar Te Espera con Valdepeñas Inmobiliaria</h3>

                        <p className="text-white opacity-50 max-w-xl mx-auto">Da el primer paso hacia la residencia de tus sueños. Valdepeñas Inmobiliaria te guía en el camino para seleccionar el lote perfecto para ti y tu familia. Benefíciate de nuestro asesoramiento experto y opciones personalizadas para cada presupuesto. Diseña tu futuro hoy con un terreno en nuestra exclusiva comunidad.</p>

                    </div>
                </div>
            </section>
    </>
  );

}

