import React from "react";


export default function Pricing() {
    const pricing = [
        {
            id: 1,
            title: 'Nuestros terrenos comienzan desde ',
            price: "524,990.00",
            user: 10,
            features: ['Financiamiento', 'Apartado']
        },
    ]
    const formattedPrice = pricing[0].price.toLocaleString();

    return (
        <>
            {/* Start */}
            <section className="relative md:py-24 py-16" id="pricing">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Precios y Financiamiento </h6>
                        
                    </div>

                    <div className="flex flex-wrap">
                        {pricing.map((item, key) => (

                            <div className="w-full md:w-1/2 lg:w-full px-0 md:px-3 mt-8" key={key}>
                                <div
                                    className="flex flex-col pt-8 pb-8 bg-zinc-50 hover:bg-white dark:bg-gray-800 dark:hover:bg-black rounded-md shadow shadow-slate-200 dark:shadow-slate-700 transition duration-500 text-center">
                                    <div className="px-8 pb-8">
                                        <h3 className="mb-6 text-lg md:text-xl font-medium dark:text-white">{item.title}</h3>
                                        <div className="mb-6 dark:text-white/70">
                                            <span className="relative -top-5 text-2xl">$</span>
                                            <span className="text-5xl font-semibold dark:text-white">{item.price}</span>
                                            <span className="inline-block ms-1">/ pesos mexicanos</span>
                                        </div>
                                        <p className="mb-6 text-slate-430 dark:text-slate-300">Vigencia de precios a Marzo 2024</p>
                                        <p className="mb-6 text-slate-430 dark:text-slate-300">con atractivas opciones de:</p>
                                    </div>
                                    <div className="border-b border-slate-200 dark:border-slate-700"></div>
                                    <ul className="self-start px-8 pt-8 text-center">
                                        {item.features.map((subitem, index) => (
                                            <li className="flex items-center my-1 text-slate-400 dark:text-slate-300" key={index}>
                                                <i className="uil uil-check-circle text-lg text-green-600 me-1"></i>
                                                <span>{subitem}</span>
                                            </li>
                                        ))}
                                    <p className="mb-6 text-slate-430 dark:text-slate-300">facilitando el camino hacia la propiedad de su nuevo hogar.</p>
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

        </>
    )


}
