import React, { useState } from "react";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import { Link } from 'react-router-dom';

import Mapa from "../assets/images/mapa.png";

import CTABackground from "../assets/images/bg/cta.png";

const images = [
    Mapa,
];

/**
 * Portfolio section
 */
export default function Portfolio() {

    const [photoIndex, setActiveIndex] = useState(0);
    const [isOpen, setOpen] = useState(false);

    const handleCLick = (index) => {
        setActiveIndex(index)
        setOpen(true);
    }

    const projectList = [
        {

            image: Mapa,
            title: 'Terrenos Residenciales',
            subtext: 'Lotes Exclusivos'
        },
    ]
    return (
        <>
            {/* Project Start  */}
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800 active" id="portfolio">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Terrenos Residenciales en Valdepeñas Inmobiliaria</h6>
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Invierte en tu Futuro - Lotes Exclusivos Disponibles</h3>

                        <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Descubre la oportunidad de construir la casa de tus sueños en Valdepeñas Inmobiliaria, donde ofrecemos lotes residenciales exclusivos con una ubicación privilegiada. </p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-2 gap-6 mt-8">
                        {projectList.map((item, index) => (
                                <div className="relative rounded-md shadow-sm overflow-hidden group" key={index}>
                                <img src={item.image} className="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500 w-full h-full cursor-pointer w-full h-full object-cover object-center cursor-pointer"  />
                                <div className="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>

                                <div className="content">
                                    <div className="icon absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 transition-all duration-500">
                                        <Link to="#" onClick={() => handleCLick(index)} className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon rounded-full lightbox">
                                            <i className="uil uil-camera"></i></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    
                    {isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => setOpen({ isOpen: false })}
                            onMovePrevRequest={() =>
                                setActiveIndex((photoIndex + images.length - 1) % images.length,
                                )
                            }
                            onMoveNextRequest={() =>
                                setActiveIndex((photoIndex + 1) % images.length,
                                )
                            }
                        />
                    )}
                </div>
            </section>
            {/* Project End  */}
        </>
    );

}

